<template>
    <div class="internal-sections my-click mt-20">
        <el-row :gutter="20">
            <el-col :span="18">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Litsenziatlar</div>
                    <div class="filter-internal-block">
                        <el-input
                            prefix-icon="el-icon-search"
                            v-model="input1"
                            size="small"
                        ></el-input>
                    </div>
                </div>
            </el-col>
            <el-col :span="6" class="filter_table">
                <router-link :to="{ name: 'licenseAdd' }">
                    <el-button> Ariza berish </el-button>
                </router-link>
                <el-dropdown class="setting-cheek ml-2">
                    <el-button size="small" icon="el-icon-setting"></el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="(column, index) in columns"
                            :key="index"
                        >
                            <el-checkbox
                                :checked="column.show"
                                @change="column.show = !column.show"
                                >{{ column.title }}</el-checkbox
                            >
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-breadcrumb>
            <el-breadcrumb-item> Litsenziyalar </el-breadcrumb-item>
            <el-breadcrumb-item>
                Tibbiy Litsenziyalar tabeli
            </el-breadcrumb-item>
        </el-breadcrumb>

        <div class="btn-table-top">
            <el-button>
                Barchasi <i class="el-icon-d-arrow-right"></i>
            </el-button>
            <el-button type="primary">
                Arizlar <i class="el-icon-d-arrow-right"></i>
                <span class="son-btn">99</span>
            </el-button>
            <el-button type="success">
                Ko'rilmoqda <i class="el-icon-d-arrow-right"></i>
                <span class="son-btn">99</span>
            </el-button>
            <el-button type="warning">
                Ruxshat <i class="el-icon-d-arrow-right"></i>
                <span class="son-btn">99</span>
            </el-button>
            <el-button type="danger">
                Rad etish <i class="el-icon-d-arrow-right"></i>
                <span class="son-btn">99</span>
            </el-button>
        </div>
        <div class="internal-sections-table">
            <table class="table-my-code table-bordered">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.nameFull.show">
                            {{ columns.nameFull.title }}
                        </th>

                        <th v-if="columns.idPostort.show">
                            {{ columns.idPostort.title }}
                        </th>

                        <th v-if="columns.stir.show">
                            {{ columns.stir.title }}
                        </th>

                        <th v-if="columns.telephone.show">
                            {{ columns.telephone.title }}
                        </th>

                        <th v-if="columns.confirmations.show">
                            {{ columns.confirmations.title }}
                        </th>

                        <th v-if="columns.dataContract.show">
                            {{ columns.dataContract.title }}
                        </th>

                        <th v-if="columns.data.show">
                            {{ columns.data.title }}
                        </th>

                        <th v-if="columns.btnsT.show">
                            {{ columns.btnsT.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.nameFull.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.nameFull"
                                :placeholder="columns.nameFull.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.idPostort.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.idPostort"
                                :placeholder="columns.idPostort.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.stir.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.stir"
                                :placeholder="columns.stir.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.telephone.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.telephone"
                                :placeholder="columns.telephone.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.confirmations.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.confirmations"
                                :placeholder="columns.confirmations.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.dataContract.show">
                            <el-date-picker
                                :placeholder="columns.dataContract.title"
                                v-model="filterForm.dataContract"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.data.show">
                            <el-date-picker
                                :placeholder="columns.data.title"
                                v-model="filterForm.data"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.btnsT.show"></th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="user in list"
                        :key="user.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ user.id }}</td>

                        <td v-if="columns.nameFull.show">
                            {{ user.nameFull }}
                        </td>

                        <td v-if="columns.idPostort.show">
                            {{ user.idPostort }}
                        </td>

                        <td v-if="columns.stir.show">
                            {{ user.stir }}
                        </td>

                        <td v-if="columns.telephone.show">
                            {{ user.telephone }}
                        </td>

                        <td v-if="columns.confirmations.show">
                            <div class="confirmations">
                                <span>2</span>
                                /
                                <span>2</span>
                            </div>
                        </td>

                        <td v-if="columns.dataContract.show">
                            {{ user.dataContract }}
                        </td>

                        <td v-if="columns.data.show">{{ user.data }}</td>

                        <td v-if="columns.btnsT.show">
                            <div class="d-flex w50-btn">
                                <el-button
                                    class="asosy-btn-d"
                                    icon="el-icon-view"
                                    size="medium"
                                >
                                </el-button>
                                <el-button
                                    type="success"
                                    icon="el-icon-document-copy"
                                    size="medium"
                                >
                                </el-button>
                            </div>
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <el-dropdown
                                :hide-on-click="false"
                                class="table-bottons-right"
                            >
                                <span class="el-dropdown-link more_icons">
                                    <i class="el-icon-open"></i>
                                </span>
                                <el-dropdown-menu
                                    slot="dropdown"
                                    class="dropdown-menumy-style"
                                >
                                    <el-dropdown-item icon="el-icon-view">
                                        Профиль
                                    </el-dropdown-item>

                                    <el-dropdown-item icon="el-icon-edit">
                                        Изменить
                                    </el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete">
                                        Удалить
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage4"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="400"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            appModal: false,
            sarcheBlocks: "",
            filterForm: {
                id: "",
                nameFull: "",
                idPostort: "",
                stir: "",
                telephone: "",
                confirmations: "",
                dataContract: "",
                data: "",
                btnsT: "",
            },
            list: [
                {
                    id: "1",
                    nameFull: "GOMED",
                    idPostort: "404",
                    stir: "912 875 379",
                    telephone: "+998902002020",
                    confirmations: "",
                    dataContract: "02.05.1995",
                    data: "12.12.2020",
                    btnsT: "",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                nameFull: {
                    show: true,
                    title: "Yuridik shaxsning nomi",
                    sortable: true,
                    column: "nameFull",
                },

                idPostort: {
                    show: true,
                    title: "Pasporti ID",
                    sortable: true,
                    column: "idPostort",
                },

                stir: {
                    show: true,
                    title: "STIR",
                    sortable: true,
                    column: "stir",
                },

                telephone: {
                    show: true,
                    title: "Телефон",
                    sortable: true,
                    column: "telephone",
                },
                confirmations: {
                    show: true,
                    title: "Tasdiqlar",
                    sortable: true,
                    column: "confirmations",
                },
                dataContract: {
                    show: true,
                    title: "Дата создания ",
                    sortable: true,
                    column: "dataContract",
                },
                data: {
                    show: false,
                    title: "Изменено ",
                    sortable: true,
                    column: "data",
                },
                btnsT: {
                    show: true,
                    title: "Click ",
                    sortable: true,
                    column: "btnsT",
                },
                settings: {
                    show: true,
                    title: "Profil",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
};
</script>
